/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/
import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import {
  CollectionDefinition,
  CollectionDefinitionProperty,
  JoinCollection,
  JoinCollectionWithDefinition,
} from "../../../model/crud.state";
import { forEach } from "lodash";
import { CrudStoreService } from "../../../services/crud.store.service";
import { AsyncPipe, NgForOf, NgIf } from "@angular/common";
import { ListComponent } from "../../list/component/list.component";

@Component({
  selector: "app-view",
  templateUrl: "./view.component.html",
  styleUrls: ["./view.component.scss"],
  imports: [NgIf, NgForOf, ListComponent, AsyncPipe],
  standalone: true,
})
export class ViewComponent {
  _collectionDefinition: CollectionDefinition = {
    collectionDefinitionProperties: [],
    joinCollections: [],
  };
  _joinCollections: (JoinCollection & {
    selector: Observable<JoinCollectionWithDefinition>;
  })[] = [];
  _element: any;
  @Input() set collectionDefinition(
    collectionDefinition: CollectionDefinition | null | undefined, // TODO: Use signals
  ) {
    if (collectionDefinition) {
      this._collectionDefinition = collectionDefinition;
      this._joinCollections = this._collectionDefinition.joinCollections.map(
        (joinCollection: JoinCollection) => {
          return {
            ...joinCollection,
            selector: this.crudStoreService.selectJoinCollectionDefinition({
              ...joinCollection,
            }),
          };
        },
      );
      this.fillData();
    }
  }
  @Input() set element(element: any) {
    if (element) {
      this._element = element;
      this.fillData();
    }
  }

  fillData() {
    if (this._joinCollections && this._element) {
      this._collectionDefinition.joinCollections.forEach(
        (joinCollection: JoinCollection) => {
          this.crudStoreService.getJoinCollectionData(
            joinCollection,
            this._element,
          );
        },
      );
    }
  }
  constructor(private crudStoreService: CrudStoreService) {}
}
