/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/

import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { Subject } from "rxjs";
import { LoginStoreService } from "../services/login.store.service";
import { takeUntil, tap } from "rxjs/operators";
import { LayoutStoreService } from "../../../../layout/services/layout-store.service";
import { NgClass, NgIf } from "@angular/common";
import { MatFormField } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatButton } from "@angular/material/button";

@Component({
  selector: "app-log-in-form",
  templateUrl: "./log-in-form.component.html",
  styleUrls: ["./log-in-form.component.scss"],
  imports: [
    ReactiveFormsModule,
    NgClass,
    NgIf,
    MatFormField,
    MatInput,
    MatButton,
  ],
  standalone: true,
})
export class LogInFormComponent implements OnInit, OnDestroy {
  public destroy$: Subject<boolean> = new Subject<boolean>();
  public error = false;
  public logged = false;
  public logging = false;
  public logInForm: FormGroup = new FormGroup<any>({});
  public logo = "";
  public redirect = "/";

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private loginStoreService: LayoutStoreService,
    private router: Router,
  ) {}

  public keyDownF(event: { keyCode: number }) {
    if (event.keyCode === 13) {
      this.logIn();
    }
  }

  public logIn() {
    this.logging = true;
    let { username, password } = this.logInForm.getRawValue();
    this.loginStoreService.login(username, password);
    /*        this.accountService
            .logIn(new Login(
                this.logInForm.controls.username.value,
                this.logInForm.controls.password.value,
            ))
            .then((login) => {
                // if (login.status === 0) {
                // this.logged = true;
                //  this.error = false;
                //  window.location.href = this.urlBase + 'main';
                // } else {
                this.logged = false;
                this.logging = false;
            }).catch((login) => {
            this.logged = false;
            this.logging = false;
        });*/
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  public ngOnInit() {
    // this.layoutService.getLogo().toPromise().then((s) => this.logo = s.logo);
    this.logInForm = this.fb.group({
      username: null,
      password: null,
    });

  }

  public sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustStyle("url(" + url + ")");
  }
}
