<table *ngIf="collectionDefinition" class="table table-hover">
  <thead>
    <tr>
      <th
        *ngFor="
          let field of collectionDefinition.collectionDefinitionProperties
        "
        scope="col"
      >
        {{ field.name }}
      </th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of list" (click)="view(element.id)">
      <td
        *ngFor="
          let field of collectionDefinition.collectionDefinitionProperties
        "
      >
        <ng-container *ngIf="field.isPrimitive; else object">
          {{ element[field.name] }}
        </ng-container>

        <ng-template #object
          ><app-draw-element-table
            [jsonElement]="element[field.name]"
          ></app-draw-element-table
        ></ng-template>
      </td>
    </tr>
  </tbody>
</table>
