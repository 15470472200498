<div
  class="login-container main"
  style="max-width: 100% !important; height: 100%"
>
  <div class="row" style="height: 100%">
    <div
      class="col-md-5"
      style="display: flex; justify-content: center; align-items: center"
    >
      <div>
        <div
          *ngIf="logo"
          [style.background-image]="sanitize(logo)"
          class="logo"
        ></div>
        <h1>Iniciar Sesión</h1>
        <form (keydown)="keyDownF($event)" [formGroup]="logInForm">
          <mat-form-field class="example-full-width">
            <input
              formControlName="username"
              matInput
              name="username"
              placeholder="Username"
              required
            />
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <input
              formControlName="password"
              matInput
              name="password"
              placeholder="Password"
              required
              type="password"
            />
          </mat-form-field>
          <span *ngIf="error">Verifique los datos ingresados</span>
          <span *ngIf="logged">Acceso correcto, ingresando...</span>
        </form>
        <button
          (click)="logIn()"
          [disabled]="logging"
          [ngClass]="logging ? 'LoadingBg' : ''"
          class="color-4 loginb"
          mat-button
        >
          <ng-container *ngIf="logging">Accendiento...</ng-container>
          <ng-container *ngIf="!logging">Iniciar Sesión</ng-container>
        </button>
      </div>
    </div>
    <div class="col-md-7 imageContainer"></div>
  </div>
</div>
