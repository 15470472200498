/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthenticateRequest, User } from "./model/authenticate.request";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  authenticate(request: AuthenticateRequest): Observable<User> {
    return this.httpClient.post<User>(
      `${environment.loginUrl}/user/authenticate`,
      request,
    );
  }

  refreshToken(refreshToken: string): Observable<User> {
    let params = new HttpParams().set("refreshToken", refreshToken);
    return this.httpClient.post<User>(
      `${environment.loginUrl}/user/refresh-token`,
      {},
      { params },
    );
  }

  revokeToken(token: string): Observable<User> {
    return this.httpClient.post<any>(
      `${environment.loginUrl}/user/revoke-token`,
      { token },
    );
  }
}
