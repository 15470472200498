<ng-container *ngIf="_collectionDefinition">
  <div class="container fill-form">
    <div class="row">
      <div
        *ngFor="
          let definitionProperty of _collectionDefinition.collectionDefinitionProperties;
          index as i
        "
        class="col-md-12"
      >
        <div class="fill-form-content">
          <div *ngIf="definitionProperty.name">
            {{ definitionProperty.name }}
          </div>
          <div *ngIf="_element">{{ _element[definitionProperty.name] }}</div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div *ngFor="let joinCollection of _joinCollections">
      <ng-container
        *ngIf="joinCollection.selector | async as joinCollectionWithDefinition"
      >
        <app-list
          [collectionDefinition]="
            joinCollectionWithDefinition.collectionDefinition
          "
          [list]="joinCollectionWithDefinition.collectionData"
          [entity]="joinCollectionWithDefinition.joinCollection.entity"
        ></app-list>
      </ng-container>
    </div>
  </div>
</ng-container>
