/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CrudMainComponent } from "./main/crud-main.component";
import { ListPageComponent } from "./components/list/page/list.page.component";
import { ViewPageComponent } from "./components/view/page/view.page.component";

export const crudRoutes: Routes = [
  { path: "", component: ListPageComponent },
  { path: "view/:id", component: ViewPageComponent },
  // {
  //   path: "view/:id",
  //   loadChildren: () => import("./crud.module").then((m) => m.CrudModule),
  // },
];
