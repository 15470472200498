/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/
import { provideRouter, Routes } from "@angular/router";
import { provideState } from "@ngrx/store";
import { provideEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { CrudReducers } from "./shared-components/crud/store/crud.reducers";
import { CrudStoreService } from "./shared-components/crud/services/crud.store.service";
import { CrudSelectors } from "./shared-components/crud/store/crud.selectors";
import { CrudEffects } from "./shared-components/crud/store/crud.effects";
import { crudRoutes } from "./shared-components/crud/crud-routing.module";
import { importProvidersFrom } from "@angular/core";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { loginRoutes } from "./routable-components/public/login/login-routing.module";
import {
  layoutFeatureName,
  layoutReducer,
} from "./layout/store/layout.reducers";
import { LayoutEffects } from "./layout/store/layout.effects";
import {CrudActions} from "./shared-components/crud/store/crud.actions";

export const routes: Routes = [
  {
    path: "admin/generic-management/:entity",
    loadComponent: () =>
      import("./shared-components/crud/main/crud-main.component").then(
        (mod) => mod.CrudMainComponent,
      ),
    providers: [
      CrudActions,
      CrudReducers,
      CrudSelectors,
      CrudEffects,
      CrudStoreService,
      importProvidersFrom(NgbModalModule),
    ],
    children: crudRoutes,
  }
];
