/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/
import { Component, Input } from "@angular/core";
import { NgForOf } from "@angular/common";

@Component({
  selector: "app-draw-element-table",
  templateUrl: "./draw-element-table.component.html",
  styleUrls: ["./draw-element-table.component.scss"],
  imports: [NgForOf],
  standalone: true,
})
export class DrawElementTableComponent {
  @Input() jsonElement: any = {};

  get jsonFields() {
    return Object.keys(this.jsonElement);
  }
}
