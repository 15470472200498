/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/
import { Component, importProvidersFrom, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from "@angular/router";
import { CrudStoreService } from "../../../services/crud.store.service";
import { Observable } from "rxjs";
import { CollectionDefinition } from "../../../model/crud.state";
import { NgForOf, NgIf } from "@angular/common";
import { DrawElementTableComponent } from "../../draw-element-table/draw-element-table.component";
import { NgbModalModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
  imports: [NgForOf, NgIf, DrawElementTableComponent],
  standalone: true,
})
export class ListComponent {
  @Input() list?: any[] | null | undefined;
  @Input() collectionDefinition?: CollectionDefinition | null | undefined;
  @Input() entity?: string | null | undefined;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private crudStoreService: CrudStoreService,
  ) {}

  edit(event: MouseEvent, elementId: string): void {
    event.preventDefault();
    event.stopPropagation();
    this.crudStoreService.open(elementId);
  }

  view(elementId: string): void {
    this.router.navigate(
      [...(this.entity ? [this.entity] : []), "view", elementId],
      {
        relativeTo: this.activatedRoute,
      },
    );
  }
}
