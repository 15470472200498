/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/
import { Component, OnInit } from "@angular/core";
import { CrudStoreService } from "../../../services/crud.store.service";
import { Observable } from "rxjs";
import { CollectionDefinition } from "../../../model/crud.state";
import { ListComponent } from "../component/list.component";
import { AsyncPipe } from "@angular/common";
import { AddEditPageComponent } from "../../../modals/add-edit/page/add-edit.page.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-list.private-page",
  templateUrl: "./list.page.component.html",
  styleUrls: ["./list.page.component.scss"],
  imports: [ListComponent, AsyncPipe],
  standalone: true,
})
export class ListPageComponent {
  constructor(
    private crudStoreService: CrudStoreService,
    private modalService: NgbModal,
  ) {
    this.crudStoreService.selectCollectionName().subscribe(() => {
      this.crudStoreService.getAll();
    });
    this.selectList$ = this.crudStoreService.selectList();
    this.selectCollectionDefinition$ =
      this.crudStoreService.selectCollectionDefinition();
  }

  selectList$: Observable<any[]>;
  selectCollectionDefinition$: Observable<CollectionDefinition>;
  //ngOnInit(): void {}

  add() {
    // this.crudStoreService.setElement(null);
    // if (this.modalRef) {
    //   this.modalRef.close();
    //   this.modalRef = null;
    // }
    // this.modalRef = this.modalService.open(AddEditPageComponent);
    // if (elementId) {
    //   this.modalRef.componentInstance.edit(elementId);
    // }
    this.crudStoreService.open();
  }
}
