/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { GetBrandingResponse } from "../model/get-branding.response";
import { Currency } from "../model/currency";

@Injectable({
  providedIn: "root",
})
export class LayoutService {
  constructor(private http: HttpClient) {}

  public getBranding(): Observable<GetBrandingResponse> {
    return this.http.get<GetBrandingResponse>(
      `${environment.backendURL}/company/GetBranding`,
    );
  }

  public getCurrencyInfo(currencyCode: string): Observable<Currency> {
    return this.http.get<Currency>(
      `${environment.backendURL}/finance/get-currency-info/${currencyCode}`,
    );
  }
}
