/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/

import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import * as actions from "../store/error-modal.actions";
import * as selectors from "../store/error-modal.selectors";
import { IErrorModalState } from "../store/model/error-modal.interface";

@Injectable({
  providedIn: "root",
})
export class ErrorModalStoreService {
  constructor(private store: Store) {}

  public clearErrors(): void {
    this.store.dispatch(actions.clearErrors());
  }

  public selectErrors(): Observable<number[]> {
    return this.store.select(selectors.selectErrors);
  }

  public selectModal(): Observable<boolean> {
    return this.store.select(selectors.selectModal);
  }

  public setError(errorCode: number): void {
    this.store.dispatch(actions.setError({ errorCode }));
  }

  public setModal(open: boolean): void {
    this.store.dispatch(actions.setModal({ open }));
  }
}
