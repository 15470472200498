/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/
import {Component, inject, OnInit} from "@angular/core";
import {Router, RouterOutlet} from "@angular/router";
import { User } from "./shared-services/user/model/authenticate.request";
import { StorageMap } from "@ngx-pwa/local-storage";
import { LayoutStoreService } from "./layout/services/layout-store.service";
import {
    combineLatest,
    filter, map,
    take,
    tap,
    withLatestFrom,
} from "rxjs/operators";
import moment from "moment";
import { combineLatestWith, of, timer } from "rxjs";
import {NgIf} from "@angular/common";

@Component({
  selector: "app-root",
  standalone: true,
    imports: [RouterOutlet, NgIf],
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  title = "multi_store_admin_frontend";
  tokenLoaded: boolean = false;
  beforeExpirationRefresh = 3;

  constructor(
    private storage: StorageMap,
    private layoutStoreService: LayoutStoreService,
    private loginStoreService: LayoutStoreService,
    private router:Router
  ) {}
  ngOnInit(): void {
    {


    }
  }
}
