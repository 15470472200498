/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/
import {ApplicationConfig, importProvidersFrom} from "@angular/core";
import {provideRouter, withDebugTracing} from "@angular/router";

import {routes} from "./app.routes";
import {provideClientHydration} from "@angular/platform-browser";
import {provideState, provideStore, StoreModule} from "@ngrx/store";
import {EffectsModule, provideEffects} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {provideStoreDevtools} from "@ngrx/store-devtools";
import {environment} from "../environments/environment";
import {provideAnimations} from "@angular/platform-browser/animations";
import {TokenInterceptor} from "./interceptors/token.interceptor";
import {
    layoutFeatureName,
    layoutReducer,
} from "./layout/store/layout.reducers";
import {LayoutEffects} from "./layout/store/layout.effects";
import {loginRoutes} from "./routable-components/public/login/login-routing.module";

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(
            [
                {
                    path: "login",
                    loadComponent: () =>
                        import("./routable-components/public/login/login/login.component").then(
                            (m) => m.LoginComponent,
                        ),
                    children: loginRoutes,
                },
                {
                    path: '',
                    loadComponent: () =>
                        import("./routable-components/private/page/private-page/private-page.component").then(
                            (m) => m.PrivatePageComponent,
                        ),
                    children: routes
                }
            ]
        ),
        provideHttpClient(withInterceptorsFromDi()),
        provideClientHydration(),
        provideStore(),
        provideEffects(),
        provideStoreDevtools({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
        provideAnimations(),
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        provideState({name: layoutFeatureName, reducer: layoutReducer}),
        provideEffects(LayoutEffects),
        provideStoreDevtools({
            maxAge: 25,
            logOnly: environment.production,
        }),
        //  importProvidersFrom(
        //  StoreModule.forRoot(),
        // // StoreDevtoolsModule.instrument(),
        //  EffectsModule.forRoot())
    ],
};
