/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { catchError, delay, map, switchMap, take, tap } from "rxjs/operators";
import { concatMap, Observable, of, race, timer } from "rxjs";
import { isPlatformServer } from "@angular/common";
import { ErrorModalStoreService } from "../shared-components/error-modals/services/error-modal.store.service";
import { SecurityService } from "../shared-services/security/security.service";
import { User } from "../shared-services/user/model/authenticate.request";
import * as moment from "moment/moment";
import { UserService } from "../shared-services/user/user.service";
import { LayoutStoreService } from "../layout/services/layout-store.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  watchdog: Observable<number> = timer(500);

  // public auth: SecurityService;

  constructor(
    private errorModalStoreService: ErrorModalStoreService,
    private securityService: SecurityService,
    // private tokenService: TokenService,
    // private tenantService: TenantService,
    @Inject(PLATFORM_ID) private platformId: any,
    private userService: UserService,
    private layoutStoreService: LayoutStoreService,
  ) {}

  public createError(codeError: number): void {
    this.errorModalStoreService.setModal(true);
    this.errorModalStoreService.setError(codeError);
  }
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<any> {
    let authenticatedRequest = this.addTokenToHeaders(request).pipe(
      switchMap((rq: HttpRequest<any>) => next.handle(rq)),
    );

    if (request.method !== "GET") {
      return authenticatedRequest.pipe(
        catchError((error: HttpErrorResponse) =>
          this.responseError(error, request),
        ),
      );
    }

    return authenticatedRequest.pipe(
        catchError((error: HttpErrorResponse) =>
            this.responseError(error, request),
        ),
    );

  }

  public responseError(
    rejection: any,
    request: HttpRequest<any>,
  ): Observable<HttpErrorResponse> {
    return this.checkBasicErrorResponseValidations(request, rejection);
  }

  private checkBasicErrorResponseValidations(
    request: HttpRequest<any>,
    rejection: HttpErrorResponse,
  ): Observable<HttpErrorResponse> {
    if (request.url.indexOf(".json") === -1) {
      rejection && rejection.error
        ? this.createError(rejection.error.statusCode)
        : this.createError(-1);
    }
    return of(rejection);
  }

  public addTokenToHeaders(
    request: HttpRequest<any>,
  ): Observable<HttpRequest<any>> {
    return this.layoutStoreService.selectUser().pipe(
      take(1),
      map((user: User) => {
        return request.clone({
          headers: request.headers.append(
            "Authorization",
            `Bearer ${user?.jwtToken}`,
          ),
          url: request.url,
        });
      }),
    );
  }
}
