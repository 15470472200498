/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/

import { Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { LogInFormComponent } from "./log-in-form/log-in-form.component";

export const loginRoutes: Routes = [
  {
    path: "",
    component: LogInFormComponent,
  },
];
