/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/

import { Injectable } from "@angular/core";
import { concatMap, mergeMap, Observable } from "rxjs";
import { provideEffects } from '@ngrx/effects';import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { map, take, tap } from "rxjs/operators";
import { Currency } from "../model/currency";
import { User } from "../../shared-services/user/model/authenticate.request";
import { StorageMap } from "@ngx-pwa/local-storage";
import * as actions from '../store/layout.actions'
import { LoginStoreService } from "../../routable-components/public/login/services/login.store.service";
import { UserService } from "../../shared-services/user/user.service";
import {LayoutService} from "../services/layout.service";
import {LayoutStoreService} from "../services/layout-store.service";
import {Router} from "@angular/router";

@Injectable()
export class LayoutEffects {
  getBranding$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.GET_BRANDING),
      concatMap(() => this.layoutService.getBranding()),
      map((branding: any) => actions.setBranding({ branding })),
    );
  });

  public getCurrencyInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.GET_CURRENCY_INFO),
      concatLatestFrom(() => this.layoutStoreService.selectCurrencyCode()),
      mergeMap(([_, currencyCode]: [any, string]) =>
        this.layoutService
          .getCurrencyInfo(currencyCode)
          .pipe(
            map((currencyInfo: Currency) =>
              actions.setCurrencyInfo({ currencyInfo }),
            ),
          ),
      ),
    );
  });

  public login$: Observable<{}> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.login),
      mergeMap(
        ({ username, password }: { username: string; password: string }) =>
          this.userService.authenticate({ username, password }),
      ),
      tap((user: User) => {
        this.storage.set("user", user).pipe(take(1)).subscribe();
      }),
      map((user: User) => {
        this.router.navigate(['/']);
        return actions.setUser({ user });
      }),
    );
  });
  public refreshToken$: Observable<{}> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.refreshToken),
      concatLatestFrom(() => this.layoutStoreService.selectUser()),
      mergeMap(([, user]: [any, User]) => {
        console.log('iuser',user)
return            this.userService.refreshToken(user.refreshToken || "")
          },
      ),
      tap((user: User) => {
        this.storage.set("user", user).pipe(take(1)).subscribe();
      }),
      map((user: User) => {
        console.log("refresh", user);
        if (user.id) {
          return actions.setUser({ user });
        } else {
          return actions.setUser({ user: {} });
        }
      }),
    );
  });

  public logout$: Observable<{}> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.logout),
      concatLatestFrom(() => this.loginStoreService.selectUser()),
      concatMap(([, user]: [any, User]) =>
        this.storage.delete("user").pipe(map(() => user)),
      ),
      mergeMap((user: User) =>
        this.userService.revokeToken(user.jwtToken || ""),
      ),
      map(() => actions.clearUser()),
    );
  });

  /* public emptyLength: number = 0;*/

  /*  public getMenuEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.GET_NAVIGATION),
      mergeMap(({ companyId }: { companyId: string }) =>
        this.companyService.GetMenu(companyId)
      ),
      map((response: GetMenuResponse) =>
        actions.setMenu({ menu: response.menu })
      )
    );
  });*/
  /*

  public getAllDataHomeEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.GET_ALL_DATA_HOME),
      mergeMap(() => this.companyService.getAllDataHome()),
      map((response: GetAllDataHomeResponse) => {
        this.sharedStoreService.setLoadingHome(false);
        return actions.setAllDataHome({ homeData: response.homeData });
      })
    );
  });



  public getCartEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.GET_CART),
      mergeMap(() => this.companyService.getCarts()),
      map((response: IGetCartResponse) =>
        actions.setCartsCode({ cart: response.cart })
      )
    );
  });

  public createCartEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.CREATE_INIT_CART),
      mergeMap((data: { cart: IShoppingCart; index: number; type: string }) =>
        this.companyService.createCart(data.cart.nameCart).pipe(
          mergeMap((response: { reservationCode: string }) =>
            this.informationModalStoreService.selectModal().pipe(
              first(),
              tap(() => this.informationModalStoreService.clearInfos()),
              tap(() => {
                this.informationModalStoreService.setAllConfigModal(
                  null,
                  this.translate.instant(
                    "INFORMATION_MODAL.SHARED.CREATE_CART"
                  ),
                  IinformationModalEnum.Success,
                  ITypeInformationModalEnum.TOAST
                );
              }),
              map(() => response)
            )
          ),
          map((response: { reservationCode: string }) => ({
            cartId: response.reservationCode,
            nameCart: data.cart.nameCart,
            uniqueProviderId: data.cart.uniqueProviderId,
            existReservations: false,
          })),
          map((newCart: IShoppingCart) =>
            actions.setCart({ cart: newCart, index: data.index })
          )
        )
      )
    );
  });

  public updateNameCartEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.UPDATE_NAME_CART),
      mergeMap(
        (data: { newCart: IShoppingCart; index: number; type: string }) =>
          this.companyService.updateNameCart(data.newCart).pipe(
            switchMap((response) =>
              this.informationModalStoreService.selectModal().pipe(
                first(),
                tap(() => this.informationModalStoreService.clearInfos()),
                tap(() => {
                  this.informationModalStoreService.setAllConfigModal(
                    null,
                    this.translate.instant(
                      "INFORMATION_MODAL.SHARED.UPDATE_CART"
                    ),
                    IinformationModalEnum.Success,
                    ITypeInformationModalEnum.TOAST
                  );
                }),
                map(() => response)
              )
            ),
            map((response: { cart: IShoppingCart }) =>
              actions.setCart({ cart: response.cart, index: data.index })
            )
          )
      )
    );
  });

  public thereIsAReservation: Observable<{ state: IThereIsAReservationEnum }> =
    createEffect(() => {
      return this.actions$.pipe(
        ofType(actions.THERE_IS_A_RESERVATION),
        map(
          (data: {
            reservationCode: string;
            lastName: string;
            type: string;
          }) => {
            this.sharedStoreService.setReservationState(
              IThereIsAReservationEnum.SEARCH
            );
            return {
              reservationCode: data.reservationCode,
              lastName: data.lastName,
            };
          }
        ),
        mergeMap((data) =>
          this.cartService
            .ThereIsAReservation(data.reservationCode, data.lastName)
            .pipe(
              map((result: ThereIsAReservationResponse) =>
                actions.setReservationState({
                  state: result.anyReservation
                    ? IThereIsAReservationEnum.RESULT_FOUND
                    : IThereIsAReservationEnum.WITHOUT_RESULT,
                })
              )
            )
        )
      );
    });
*/

  constructor(
    private actions$: Actions,
    private layoutService: LayoutService,
    private layoutStoreService: LayoutStoreService,
    private storage: StorageMap,
    private loginStoreService: LayoutStoreService,
    private router:Router,
    private userService: UserService /*    private sharedService: FinanceService,
    private sharedStoreService: SharedStoreService,
    private companyService: CompanyService,
    private cartService: CartService,
    private informationModalStoreService: InformationModalStoreService,*/ /*private translate: TranslateService*/,
  ) {}
}
