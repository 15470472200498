/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/
import { Component, OnInit } from "@angular/core";
import { CrudStoreService } from "../../../services/crud.store.service";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { CollectionDefinition } from "../../../model/crud.state";
import { ViewComponent } from "../component/view.component";
import { AsyncPipe } from "@angular/common";

@Component({
  selector: "app-view.private-page",
  templateUrl: "./view.page.component.html",
  styleUrls: ["./view.page.component.scss"],
  imports: [ViewComponent, AsyncPipe],
  standalone: true,
})
export class ViewPageComponent {
  selectCollectionDefinition$: Observable<CollectionDefinition>;
  selectElement$: Observable<any>;
  constructor(
    private crudStoreService: CrudStoreService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.crudStoreService.selectCollectionName().subscribe(() => {
      this.crudStoreService.getById(this.activatedRoute.snapshot.params["id"]);
    });
    this.selectCollectionDefinition$ =
      this.crudStoreService.selectCollectionDefinition();

    this.selectElement$ = this.crudStoreService.selectElement();
  }

  //ngOnInit(): void {}
}
