/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SecurityService {
  public baseUrl: string = `${environment.backendURL}api/user/`;

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  public getAnonymousToken(): Observable<{ token: string }> {
    return this.http.get<{ token: string }>(`${this.baseUrl}AnonymousToken`);
  }

  /*public isAuthenticated(): boolean {
        const token = this.getToken();
        // Check whether the token is expired and return
        // true or false
        return token !== null;
    }*/

  /*public logIn(login: Login): Promise<any> {
        return this.http
            .post(
                environment.backendURL + 'api/security/getToken',
                login,
            ).toPromise().then((resp: any) => {
                if (!resp.error) {
                    localStorage.setItem('auth_token', resp.token);
                    localStorage.setItem('userId', resp.userId);
                    this.router.navigate(['main']);
                }
            });
    }*/

  /*public logout() {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('auth_expiration');
    }*/
}
