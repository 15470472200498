/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/

import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IErrorModalState } from "./model/error-modal.interface";

export const selectErrorModalState =
  createFeatureSelector<IErrorModalState>("error");
// tslint:disable:typedef
export const selectModal = createSelector(
  selectErrorModalState,
  (errorModalState: IErrorModalState) => errorModalState.modal,
);
export const selectErrors = createSelector(
  selectErrorModalState,
  (errorModalState: IErrorModalState) => errorModalState.errors,
);
