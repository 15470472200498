<table class="table table-hover">
  <thead>
    <tr>
      <th *ngFor="let field of jsonFields" scope="col">
        {{ field }}
      </th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td *ngFor="let field of jsonFields">
        {{ jsonElement[field] }}
      </td>
    </tr>
  </tbody>
</table>
